<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Загрузка упражнений"
      hide-footer
      centered
      no-body
      size="md"
      body-class="p-0"
    >
      <template #default="{ }">
        <!-- form -->
        <b-form @submit.prevent="saveExercise">
          <div class="p-2">
            <upload-exercise-form
              ref="uploadForm"
              :subjects="subjects"
              :grades="grades"
            />
          </div>
          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
            >
              <span>Загрузить</span>
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="display = false"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BModal,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user/index'
import { toast } from '../../../helpers'
import UploadExerciseForm from './UploadExerciseForm.vue'

export default {
  name: 'UploadExerciseModal',
  components: {
    UploadExerciseForm,
    BModal,
    BButton,
    BForm,
  },
  props: {

  },
  data: () => ({
    display: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    dict() {
      return {
        custom: {
          subject: {
            required: 'Необходимо указать предмет',
          },
          grade: {
            required: 'Необходимо указать класс',
          },
          excel_file: {
            required: 'Не выбран файл Excel',
          },
          images_file: {
            required: 'Не выбран архив',
          },
        },
      }
    },
  },
  async created() {
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()

    this.$validator.localize('ru', this.dict)
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    async saveExercise() {
      await this.$validator.validate().then(valid => {
        if (valid) {
          this.uploadCourse()
        }
      })
    },
    async uploadCourse() {
      const data = await this.$refs.uploadForm.uploadCourse()
      if (data !== false) {
        this.$emit('created', data)
        this.display = false
        toast({ title: 'Упражнения загружены', type: 'success' })
      }
    },
    show() {
      this.display = !this.display
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-card>

    <div
      v-if="false"
      class="mb-1"
    >
      <p class="">
        Тип упражнения
      </p>
      <b-form-checkbox-group
        v-model="fl.types"
        @change="reloadExercises()"
      >
        <div class="d-flex flex-column">
          <b-form-checkbox
            v-for="type in types"
            :key="type.id"
            :value="type.id"
            class="mb-50"
          >
            {{ type.name }}
          </b-form-checkbox>
        </div>
      </b-form-checkbox-group>
    </div>

    <p>
      Предмет
    </p>
    <v-select
      v-model="fl.subjects"
      :options="subjects"
      multiple
      label="text"
      :reduce="s => s.id"
      placeholder="Все предметы"
      @input="reloadExercises"
    />

    <p class="mt-1">
      Класс
    </p>
    <v-select
      v-model="fl.grades"
      :options="grades"
      multiple
      label="name"
      :reduce="s => s.id"
      placeholder="Все классы"
      @input="reloadExercises"
    />

    <b-form-checkbox
      v-model="fl.own"
      class="mt-2"
      switch
      @change="reloadExercises()"
    >
      Показывать только мои
    </b-form-checkbox>

    <b-form-checkbox
      v-if="fl.own"
      v-model="filter.private"
      class="mt-1"
      switch
      @change="reloadExercises()"
    >
      Только личные
    </b-form-checkbox>
  </b-card>
</template>

<script>
import {
  BCard, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { restoreFilter, saveFilters } from '@/helpers'

export default {
  name: 'ExerciseFilter',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fl: this.filter,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      types: 'exercise/get_types',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
    }),
  },
  watch: {
    '$route.query': {
      handler() {
        this.restoreFilter()
      },
    },
  },
  beforeMount() {
    this.restoreFilter()
  },
  mounted() {
    if (!this.types.length) this.getTypes()
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()
  },
  methods: {
    ...mapActions({
      getTypes: 'exercise/getExerciseTypes',
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    restoreFilter() {
      this.fl = Object.assign(this.fl, restoreFilter(this.$route, this.fl))
    },
    reloadExercises() {
      saveFilters(this.$router, this.filter)
      this.$emit('updated')
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
      lg="4"
      xl="3"
    >
      <div class="mb-1 d-flex align-items-center">
        <div class="flex-fill">
          <create-exercise-modal @created="reloadList">
            <template #activator="{show}">
              <b-button
                block
                variant="primary"
                @click="show"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Создать упражнение
              </b-button>
            </template>
          </create-exercise-modal>
        </div>

        <upload-exercise-modal>
          <template #activator="{show}">
            <b-button
              title="Загрузить упражнения"
              class="ml-1 btn-icon"
              variant="outline-primary"
              @click="show"
            >
              <feather-icon icon="UploadIcon" />
            </b-button>
          </template>
        </upload-exercise-modal>

      </div>

      <exercise-filter
        class="sticky-top"
        style="top: 108px"
        :filter="filter"
        @updated="reloadList"
      />
    </b-col>
    <b-col
      cols="12"
      md="6"
      lg="8"
      xl="9"
    >
      <exercises-list
        ref="list"
        :filter="filter"
        @tagsUpdated="tagsUpdated"
        @subjectSelected="subjectSelected"
        @gradeSelected="gradeSelected"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import ExercisesList from '@/components/exercise/ExercisesList.vue'
import ExerciseFilter from '@/components/exercise/ExerciseFilter.vue'
import UploadExerciseModal from '../../components/exercise/exercise/UploadExerciseModal.vue'
import CreateExerciseModal from '../../components/exercise/exercise/CreateExerciseModal.vue'

export default {
  name: 'Courses',
  components: {
    CreateExerciseModal,
    UploadExerciseModal,
    ExerciseFilter,
    ExercisesList,
    BRow,
    BCol,
    BButton,
  },
  data: () => ({
    loadingExercises: false,
    filter: {
      own: false,
      private: false,
      subjects: [],
      grades: [],
      types: [],
      tags: [],
    },
  }),
  methods: {
    reloadList() {
      this.$refs.list.load()
    },
    tagsUpdated(tags) {
      const exerciseTags = tags.map(tag => tag.tags.id)
      this.filter.tags = [...exerciseTags]
      this.reloadList()
    },
    subjectSelected(subjectId) {
      if (!this.filter.subjects.includes(subjectId)) this.filter.subjects.push(subjectId)
      this.reloadList()
    },
    gradeSelected(gradeId) {
      if (!this.filter.grades.includes(gradeId)) this.filter.grades.push(gradeId)
      this.reloadList()
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <!-- form -->
  <div>
    <!-- subject -->
    <b-form-group
      :label="$t('page.courses.form.labels.subject')"
      label-for="subjects_id"
    >
      <v-select
        id="subjects_id"
        v-model="form.subject_id"
        v-validate="'required'"
        :options="subjects"
        clearable
        label="text"
        class="d-block"
        :reduce="s => s.id"
        placeholder="Выберите предмет"
        name="subject"
        :state="errors.first('subject') ? false : null"
      >
        <template slot="no-options">
          {{ $t('components.select.no-items') }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors.first('subject') }}</small>
    </b-form-group>
    <!-- grade -->
    <b-form-group
      label="Класс"
      label-for="subject"
    >
      <v-select
        id="grade"
        v-model="form.grade_id"
        v-validate="'required'"
        :options="grades"
        clearable
        label="name"
        class="d-block"
        :reduce="s => s.id"
        placeholder="Выберите класс"
        name="grade"
        :state="errors.first('grade') ? false : null"
      >
        <template slot="no-options">
          {{ $t('components.select.no-items') }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors.first('grade') }}</small>
    </b-form-group>
    <!-- file -->
    <b-form-group
      label="Файл Excel"
      label-for="excel_file"
    >
      <b-form-file
        id="excel_file"
        v-model="form.excel_file"
        v-validate="'required'"
        accept=".xls, .xlsx"
        :placeholder="$t('components.file-input.chose-file')"
        :drop-placeholder="$t('components.file-input.drop-file-here')"
        :browse-text="$t('components.file-input.browse')"
        name="excel_file"
        :state="errors.first('excel_file') ? false : null"
      />
      <small class="text-danger">{{ errors.first('excel_file') }}</small>
    </b-form-group>
    <!-- file -->
    <b-form-group
      label="Архив"
      label-for="archive"
    >
      <b-form-file
        id="archive"
        v-model="form.images_file"
        v-validate="'required'"
        accept=".zip, .rar, .7zip"
        :placeholder="$t('components.file-input.chose-file')"
        :drop-placeholder="$t('components.file-input.drop-file-here')"
        :browse-text="$t('components.file-input.browse')"
        name="archive"
        :state="errors.first('archive') ? false : null"
      />
      <small class="text-danger">{{ errors.first('archive') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'UploadExerciseForm',
  components: {
    BFormGroup,
    BFormFile,
    vSelect,
  },
  props: {
    subjects: {
      type: Array,
      default: () => [],
    },
    grades: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    form: {
      excel_file: null,
      images_file: null,
      subject_id: null,
      grade_id: null,
    },
  }),
  mounted() {
    this.form = {
      excel_file: null,
      images_file: null,
      courses_name: '',
      subject_id: null,
      grade_id: null,
    }
  },
  methods: {
    async uploadCourse() {
      const data = await this.$validator.validate().then(async valid => {
        if (valid) {
          const formData = new FormData()
          Object.keys(this.form).forEach(key => {
            formData.append(key, this.form[key])
          })
          await this.$http.post('/exercises/upload', formData)
            .then(response => response)
            .catch(() => {})
          return true
        }
        return false
      })
      return data
    },
  },
}
</script>
